.user-panel-overlay,
.search-panel-overlay {
  display: block;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
  z-index: 800;
  transition: opacity 0.3s ease, width 0s 0.3s;
}

.user-panel-overlay {
  z-index: 10000;
}

.user-panel,
.search-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 60px;
  width: $seachPanel-width;
  background: $alt-white;
  background: var(--alt-white, $alt-white);
  z-index: 1000;
  overflow: hidden;
  transition: transform 0.3s ease;
  transform: translate3d(-$seachPanel-width + $sidebar-width, 0, 0);

  .close-panel {
    background: none;
    border: none;
    box-shadow: none;
    position: absolute;
    right: 0;
    top: 10px;
  }

  .alert {
    margin: 0;
    padding: 0.25em 1em;
    font-size: $font-size-xs;
  }

  &.open {
    transform: translate3d($sidebar-width, 0, 0);

    + .user-panel-overlay,
    + .search-panel-overlay {
      pointer-events: auto;
      opacity: 0.5;
      width: 100%;
      transition: opacity 0.3s ease;
    }
  }

  i {
    transition: color 0.3s ease;
    margin-right: 0.5em;
    position: relative;
    top: -1px;

    &.icon-elec {
      color: $blue;
      color: var(--blue, $blue);
    }

    &.icon-gaz {
      color: $purple;
      color: var(--purple, $purple);
    }
  }

  .text-input {
    position: relative;
    margin-bottom: 15px;

    label {
      font-size: $font-size-lg;
      position: absolute;
      top: 12px;
      color: $gray-light;
      color: var(--gray-light, $gray-light);
      transition: all 0.3s ease;
      cursor: text;
    }

    input {
      display: block;
      width: 100%;
      border: none;
      border-bottom: 2px solid $gray-lighter;
      border-bottom: 2px solid var(--gray-lighter, $gray-lighter);
      border-radius: 0;
      background: none;
      font-size: $font-size-lg;
      padding: 12px 0;

      &.has-value + label,
      &:focus + label {
        top: -12px;
        font-size: $font-size-sm;
      }
    }
  }

  .radio-input-tabs {
    border-bottom: 1px solid $gray-lighter;
    border-bottom: 1px solid var(--gray-lighter, $gray-lighter);

    .form-group {
      padding: 0 15px;

      &:first-child {
        padding-left: 0;
      }
    }

    label {
      font-size: $font-size-base;
      padding: 15px 0;
      color: $gray;
      color: var(--gray, $gray);
      position: relative;
      cursor: pointer;

      &:hover {
        color: $black;
        color: var(--black, $black);

        i {
          color: $black;
          color: var(--black, $black);
        }
      }

      i {
        margin-left: -0.25em;
        transition: none;
        color: $gray;
        color: var(--gray, $gray);
      }
    }

    input {
      display: none;

      &:checked + label {
        color: $black;
        color: var(--black, $black);

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 5px;
          background: $green;
          background: var(--green, $green);
        }

        i {
          &.icon-elec {
            color: $blue;
            color: var(--blue, $blue);
          }

          &.icon-gaz {
            color: $purple;
            color: var(--purple, $purple);
          }
        }
      }
    }
  }

  .radio-input {
    label {
      margin-right: 1.5em;
      padding: 20px 0;
      cursor: pointer;

      &:before {
        content: "";
        display: block;
        position: relative;
        margin-right: 0.5em;
        height: 1em;
        width: 1em;
        border-radius: 100%;
        top: -1px;
        border: 1px solid $gray-lighter;
        border: 1px solid var(--gray-lighter, $gray-lighter);
      }
    }

    input {
      display: none;

      &:checked + label:before {
        background: $green;
        background: var(--green, $green);
        box-shadow: inset 0 0 0 2px $white;
        box-shadow: inset 0 0 0 2px var(--white, $white);
      }
    }
  }

  .radio-input-btn {
    label {
      margin-right: 10px;
      color: $gray-light;
      color: var(--gray-light, $gray-light);
      font-size: $font-size-base;
      border: none;
      border-radius: none;
      position: relative;
      overflow: visible;
    }

    .form-group:first-child {
      label:after {
        content: "";
        position: absolute;
        background: $gray-lighter;
        background: var(--gray-lighter, $gray-lighter);
        right: -5px;
        top: 12px;
        bottom: 10px;
        width: 1px;
      }
    }

    input {
      display: none;

      &:checked + label {
        color: $green;
        color: var(--green, $green);
      }
    }
  }

  .btn-group {
    border-bottom: 1px solid $gray-lighter;
    border-bottom: 1px solid var(--gray-lighter, $gray-lighter);
    width: 100%;
    padding: 30px 0 15px;

    .btn {
      padding: 0;
      margin-right: 30px;
      color: $gray;
      color: var(--gray, $gray);
      cursor: pointer;
      box-shadow: none;
    }
  }

  .panel-flex {
    flex-direction: column;
    height: calc(100% - 194px);

    > .col {
      overflow-y: auto;
    }

    ul {
      list-style: none;
      padding: 0;
      color: $gray;
      color: var(--gray, $gray);

      ul {
        padding: 0 0 0 1.5em;
      }
    }

    .liste-filtree {
      h5 {
        font-size: $font-size-base;
      }

      ul {
        margin-bottom: 30px;

        mark {
          padding: 0.2em 0;
          color: $white;
          color: var(--white, $white);
        }
      }
    }
  }

  .panel-header,
  .panel-footer {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 20px 0;
  }

  .panel-header {
    border-bottom: 2px solid $gray-lighter;
    border-bottom: 2px solid var(--gray-lighter, $gray-lighter);
  }

  .panel-footer {
    border-top: 2px solid $gray-lighter;
    border-top: 2px solid var(--gray-lighter, $gray-lighter);

    .btn {
      margin-left: 10px;
    }

    .btn-link {
      box-shadow: none;
    }
  }

  .btn {
    cursor: pointer;
    border-radius: 20px;
  }
}

.search-panel {
  .panel-flex {
    > .col {
      padding-top: 30px;
      padding-bottom: 150px;
    }
  }
}

.user-panel {
  left: auto;
  right: 0;
  transform: translate3d($seachPanel-width, 0, 0);
  z-index: 10010;
  padding-top: 0;
  padding-bottom: 0;

  .user-infos {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &.open {
    transform: translate3d(0, 0, 0);
  }

  .panel-header {
    padding-top: 54px;
  }

  .text-input {
    margin-bottom: 20px;

    label {
      font-size: $font-size-base;
    }

    input {
      font-size: $font-size-base;
      border-bottom-width: 1px;
      padding-bottom: 8px;

      &.has-value + label,
      &:focus + label {
        top: -4px;
        font-size: $font-size-xs;
      }
    }
  }
}

.panel-choice {
  top: 10px;
  position: absolute;

  .form-group {
    display: inline-block;
  }
}

.user-security {
  padding-top: 120px;

  .panel-flex {
    height: 100%;

    > .col {
      flex-grow: 0;
      flex-basis: unset;
    }
  }
}
