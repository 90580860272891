@import "variables";
@import "bootstrap";

@import "buttons";
@import "panels";

[class^="icon-"], [class*=" icon-"] {
  height: 14px;
  width: 14px;
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}

.form-control {
  border-radius: .2rem;
  height: calc(1.5em + 0.75rem + 3px);
  padding: 7px 12px 6px;
  &:focus {
    box-shadow: none;
    border-color: $green;
  }
}

// Turn Off Input Spinners
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Prod issue
.form-control.is-invalid {
  background-position: center right calc(.375em + .1875rem);
}

// Modal
.pdf-modal {
  .modal-body {
    padding: 0;
    height: calc(100vh - 150px);
  }
}

// react-split-pane

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
cursor: not-allowed;
}
.Resizer.disabled:hover {
border-color: transparent;
}
