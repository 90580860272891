.btn {
  &:not([disabled]):not(.disabled) {
    cursor: pointer;
  }
}

.btn-primary {
  border-color: $green;
  border-color: var(--green, $green);
  background-color: $green;
  background-color: var(--green, $green);

  &:hover {
    border-color: $green-dark;
    border-color: var(--green-dark, $green-dark);
    background-color: $green-dark;
    background-color: var(--green-dark, $green-dark);
  }

  &:not([disabled]):not(.disabled) {
    &:active, &.active {
      border-color: $green-darken;
      border-color: var(--green-darken, $green-darken);
      background-color: $green-darken;
      background-color: var(--green-darken, $green-darken);
      box-shadow: none;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-link {
  color: $green;
  color: var(--green, $green);

  &:hover {
    color: $green-dark;
    color: var(--green-dark, $green-dark);
  }

  &:active, &:focus {
    color: $green-darken;
    color: var(--green-darken, $green-darken);
  }
}

.btn-secondary {
  color: $gray-dark;
  color: var(--gray-dark, $gray-dark);
  background: none;
  border-color: $gray-lighter;
  border-color: var(--gray-lighter, $gray-lighter);

  &:not([disabled]):not(.disabled) {
    &:hover,
    &:active {
      color: $gray-dark;
      color: var(--gray-dark, $gray-dark);
      background: none;
      box-shadow: none;
    }

    &:focus {
      background: none;
      box-shadow: none;
    }
  }

  &.disabled {
    background: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.btn.rounded {
  border-radius: 38px !important;
  padding-left: 30px;
  padding-right: 30px;
}

.btn.standard-width {
  width: 200px;
}

.btn.btn-link.prec-button {
  border: none;
  color: $gray-light;
  font-size: 16px;

  &:hover {
    color: $gray-light;
  }
}

.btn-table {
  padding: 0;
  font-size: inherit;
  border-radius: 0;
  border: none;
  background: none;
  color: $gray-lighter;

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: $gray;
    background: none;
    box-shadow: none;
  }

  &:hover {
    color: $primary !important;
  }
}
